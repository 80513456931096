import {Injectable} from '@angular/core';
import {Dictionary, Facepay} from '../../environments/environment';
import {CurrencyPipe,TitleCasePipe, UpperCasePipe} from '@angular/common';

@Injectable({
    providedIn: 'root'
})

export class ParagraphsService {

    businessName;
    business;
    dictTerm;
    customerFirstName;
    customerLastName;
    amount;

    constructor(
      private currencyPipe: CurrencyPipe,
      
    ) {       
    }
    
    getNotNulValue( value ){
        if (value && value != null && value !== undefined && value !== "null" && value !== "undefined") {
           
           return value;
        }else{
          return "";
        }
    
      }

      replaceReservedwords( paragraphs ){

        const regexaa = /\{\{[A-Za-z0-9\.]+\}\}/gi;
        let mergedOptions = paragraphs;
        let paragraphsService = this;
        let newMessage = mergedOptions.replaceAll( regexaa, function( word){
          let lowcaseWord = word.toLowerCase();
          console.log( " ===== message lowcaseWord 111 = " + lowcaseWord + " ; " + lowcaseWord === "{{businessname}}" )
          if ( lowcaseWord === "{{businessname}}" ){
            return paragraphsService.getNotNulValue( paragraphsService.businessName );
          }else if (  lowcaseWord === "{{customers}}" ){
            return paragraphsService.getNotNulValue( paragraphsService.dict( "customers" ) )
          }else if (  lowcaseWord === "{{customer}}" ){
            return paragraphsService.getNotNulValue( paragraphsService.dict( "customers" ) )
          }else if (  lowcaseWord === "{{invoice}}" ){
            return paragraphsService.getNotNulValue( paragraphsService.dict( "invoice" ) )
          }else if (  lowcaseWord === "{{customerfirstname}}" ){
            return paragraphsService.getNotNulValue( paragraphsService.customerFirstName )
          }else if (  lowcaseWord === "{{customerlastname}}" ){
            return paragraphsService.getNotNulValue( paragraphsService.customerLastName )
          }else if (  lowcaseWord === "{{amount}}" ){
            if ( paragraphsService.amount ){
              return paragraphsService.currencyPipe.transform(paragraphsService.amount, 'USD', 'symbol', '1.2-2');
            }
          }
        });
        
        return newMessage;
      }
    
      dict(term) {
        let dictTerm = this.business.getOption('dict');  
    
        if (this.business && this.business.getOption('dict') && dictTerm[term]) {
          // console.log( " ===== dict industry 1111: " + this.business.getOption('industry') + " ; term = " + term + " ; dict : " + Dictionary[this.business.getOption('industry')][term] + " ; dictTerm[term]) " + dictTerm[term] );
          return dictTerm[term]
        }else{
            if( this.business && this.business.getOption('industry') && Dictionary[this.business.getOption('industry')] && Dictionary[this.business.getOption('industry')][term] ){
              return Dictionary[this.business.getOption('industry')][term]
            }else{
              return  Dictionary['default'][term]
            }              
        }
    
      }

}